const forgotPasswordBtn = document.querySelector("#forgot-password-btn");
const backBtn = document.querySelector("#back-btn");
const loginContainer = document.querySelector("#login-form");
const requestResetPasswordContainer = document.querySelector("#request-password-reset-container");

forgotPasswordBtn.addEventListener("click", function () {
   toggleLoginAndRequestResetPassword();
});

backBtn.addEventListener("click", function () {
   toggleLoginAndRequestResetPassword();
});

function toggleLoginAndRequestResetPassword() {
   if (loginContainer.classList.contains("hide")) {
      loginContainer.classList.remove("hide");
   } else {
      loginContainer.classList.add("hide");
   }
   if (requestResetPasswordContainer.classList.contains("hide")) {
      requestResetPasswordContainer.classList.remove("hide");
   } else {
      requestResetPasswordContainer.classList.add("hide");
   }
}